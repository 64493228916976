import { Controller } from '@hotwired/stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex() {
    document.body.classList.add('wait');
  }

  afterReflex() {
    document.body.classList.remove('wait');
  }
}
