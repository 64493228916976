import ApplicationController from './application_controller';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';

hljs.registerLanguage('json', json);

export default class extends ApplicationController {
  connect() {
    hljs.highlightElement(this.element);
  }
}
