import ApplicationController from '../application_controller';
import Chart from 'chart.js/auto';
import { Tooltip } from 'chart.js';

Chart.defaults.font.size = 16;

Tooltip.positioners.xAxisPositioner = function(elements, eventPosition) {
  return { x: eventPosition.x, y: this.chart.height };
};

export default class extends ApplicationController {
  static values = { data: Array, resolution: String };

  static timelineSettings = [
    {
      resolution: '1 minute',
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      includeHalfHours: true
    },
    {
      resolution: '10 minutes',
      hours: [3, 6, 9, 12],
      includeHalfHours: false
    },
    {
      resolution: '1 hour',
      hours: [6, 12],
      includeHalfHours: false
    },
    {
      resolution: '2 hours',
      hours: [12],
      includeHalfHours: false
    }
  ];

  connect() {
    this.element.controller = this;
    this.chart = new Chart(this.element, this.config);
  }

  disconnect() {
    this.chart.destroy();
  }

  get config() {
    return {
      type: 'bar',
      data: {
        datasets: [
          {
            data: this.coordinates,
            backgroundColor: 'rgb(114, 168, 143)',
            hoverBackgroundColor: 'rgb(56, 128, 94)',
            categoryPercentage: 0.95
          }
        ]
      },
      options: {
        aspectRatio: 3.5,
        layout: {
          padding: {
            bottom: 40
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: () => null,
              label: (context) => `${context.label}: ${context.formattedValue}`
            },
            position: 'xAxisPositioner',
            yAlign: 'top'
          }
        },
        scales: {
          x: {
            position: 'top',
            grid: {
              offset: false,
              color: 'transparent',
              borderColor: 'transparent',
              tickColor: 'rgb(0, 0, 0, 0.1)'
            },
            ticks: {
              callback: this.formatTimelineTick,
              maxRotation: 0
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 8,
              precision: 0
            }
          }
        }
      }
    };
  }

  dataValueChanged(value) {
    this.coordinates = value.slice(0, -1).map((item, index) => {
      let x = [item[0], value[index + 1][0]];
      x = x.map(str => this.formatTimestamp(str)).join(' – ');
      return { x, y: item[1] };
    });
  }

  formatTimestamp(iso8601) {
    return new Date(iso8601).toLocaleString('en-US', {
      hour: '2-digit',
      minute: 'numeric',
      hour12: true
    });
  }

  formatTimelineTick(value) {
    const timepoints = this.chart.canvas.controller.visibleTimepoints;
    value = this.getLabelForValue(value);

    if (timepoints.includes(value.substring(0, 5))) {
      return value.substring(0, 8).replace(/(^0|:00)/g, '');
    }
  }

  get visibleTimepoints() {
    const { hours, includeHalfHours } = this.timelineSettings;

    return hours.
      map(h => `${h}`.padStart(2, '0')).
      flatMap(h => [`${h}:00`, includeHalfHours && `${h}:30`]).
      filter(Boolean);
  }

  get timelineSettings() {
    return this.constructor.timelineSettings.find(s => {
      return s.resolution == this.resolutionValue;
    });
  }
}
