import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import controller from './application_controller';

const application = Application.start();
const context = require.context('.', true, /_controller\.js$/);

application.load(definitionsFromContext(context));
application.consumer = consumer;
StimulusReflex.initialize(application, { controller, isolate: true });
StimulusReflex.debug = process.env.RAILS_ENV === 'development';
