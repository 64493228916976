import 'bootstrap';
import 'es6-promise/auto';
import 'regenerator-runtime/runtime';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import './channels';
import './controllers';

Rails.start();
ActiveStorage.start();
